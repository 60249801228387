import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './modules/shared/shared.module';
import { AngularmaterialModule } from './modules/angularmaterial/angularmaterial.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { httpInterceptorProvider } from './providers/httpInterceptor';
import { hashLocation } from './providers/hashLocation';
import { NgxPaginationModule } from 'ngx-pagination';
// import { JwtService } from './services/jwt.services';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { AppNamePipe } from './app-name.pipe';



@NgModule({
  declarations: [AppComponent, LoginComponent, AppNamePipe],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularmaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    BrowserAnimationsModule,
    SharedModule,
    SelectDropDownModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    // NgxSpinnerModule,
    NgHttpLoaderModule.forRoot(), // <============ Don't forget to call 'forRoot()'!
  ],
  exports: [AngularmaterialModule, SharedModule],
  providers: [hashLocation, httpInterceptorProvider],
  bootstrap: [AppComponent],
})
export class AppModule { }
