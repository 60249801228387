import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private router: Router
    ) {}
  logopath: string;
  viewEndUrl:any=[];
  role:any;

  ngOnInit() {
    this.viewEndUrl= localStorage.getItem('viewEndPoint')
    console.log(this.viewEndUrl)
    this.role=JSON.parse(localStorage.getItem('role'));
    console.log(this.role)
    this.logopath = '../../../../../assets/img/logo.png';
  }

  onChangeCourseBotton(e: Event) {
    e.stopPropagation();
  }

  routeToRoles(){
    console.log("routeToRoles_1", );
    this.router.navigate(["user-management/roles"]);
  }

  
  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }
}
