<div class="login-page">
    <nav class="navbar navbar-color-on-scroll navbar-transparent fixed-top navbar-expand-lg" color-on-scroll="100"
        id="sectionsNav">
        <div class="container">
            <div class="navbar-translate">
                <a class="navbar-brand text-white">{{ '' | appName }} | Admin</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                    <span class="navbar-toggler-icon"></span>
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <div class="collapse navbar-collapse"></div>
        </div>
    </nav>
    <div class="page-header header-filter" style="
      background-color: white;
      background-size: cover;
      background-position: top center;
    ">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-sm-6 ml-auto mr-auto d-flex" style="min-height: 100vh">
                    <div class="card card-signup align-self-center">
                        <form class="form" [formGroup]="loginForm">
                            <div class="card-header card-header-primary text-center">
                                <h4 class="card-title">Login</h4>
                            </div>
                            <p class="description text-center" *ngIf="">
                                Or Be Classical
                            </p>
                            <div class="form-group">
                                <div class="mb-4">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <i class="material-icons">mail</i>
                                            </span>
                                        </div>
                                        <input type="email" class="form-control" placeholder="Email" name="email"
                                            formControlName="email" name="email" />
                                    </div>
                                    <div *ngIf="submitted && f.email.errors && !f.password.errors" class="text-left">
                                        <div *ngIf="f.email.errors.required" class="text-danger left-text">
                                            Email is required !
                                        </div>
                                    </div>

                                    <span class="error-msg text-danger left-text"
                                        *ngIf="loginForm?.get('email')?.hasError('pattern')">
                                        Please enter correct email.
                                    </span>
                                </div>
                                <div>


                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <i class="material-icons">lock_outline</i>
                                            </span>
                                        </div>
                                        <input type="password" class="form-control" placeholder="Password"
                                            formControlName="password" name="password" />
                                    </div>
                                    <div *ngIf="submitted && f.password.errors && !f.email.errors" class="text-left">
                                        <div *ngIf="f.password.errors.required" class="text-danger left-text">
                                            Password is required !
                                        </div>

                                    </div>

                                    <div *ngIf="submitted && f.email.errors && f.password.errors" class="text-left">
                                        <div *ngIf="f.email.errors.required" class="text-danger left-text">
                                            Please enter email and password
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="footer">
                                <div class="w-100 text-center">
                                    <a class="btn btn-primary text-white" (click)="login()">Submit
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>