<nav class="navbar navbar-expand-lg navbar-absolute fixed-top custom-navbar">
  <div class="topNavBar">
    <div class="userProfileContainer">
      <img src="../../../../../assets/img/newImg.svg " alt="" />
    </div>
    <div class="userProfileContainer">
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-secondary dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <img src="../../../../../assets/img/qrImg.svg" alt="" />
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <button class="dropdown-item" type="button" (click)="logout()">Logout</button>
        </div>
      </div>    </div>
  </div>
  <div class="container-fluid extra-col">
    <div class="navbar-wrapper">
      <a class="navbar-brand custom-resturant-name" href="#pablo">
        {{ headerText }}
      </a>
    </div>
    <div class="collapse navbar-collapse justify-content-end">
      <ul class="navbar-nav">
        <li class="nav-item">
          <!-- <button class="btn btn-sm btn-danger" (click)="logout()">
            <span class="material-icons">logout</span> Logout
          </button> -->
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- End Navbar -->
