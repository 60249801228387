import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PreloadAllModules } from '@angular/router';
import { ProtectGuard } from './guards/protect.guard';
import { LogoutGuard } from './guards/logout.guard';
import { AuthGuard } from './guards/auth.guard';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '*', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    canActivate: [ProtectGuard],
    component: LoginComponent,
  },
  // {
  //   path: "dashboard",
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import("./modules/dashboard/dashboard.module").then(m => m.DashboardModule)
  // },

  {
    path: 'common',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/shared/shared.module').then((m) => m.SharedModule),
  },
  // {
  //   path: "users",
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import("./modules/users/users.module").then(m => m.UsersModule)
  // }, 

  {
    path: 'restaurant',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/restaurant/restaurant.module').then(
        (m) => m.RestaurantModule
      ),
      // canActivateChild: [AuthGuard],  

  },
  {
    path: 'package-management',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/packages/package.module').then((m) => m.PackageModule),
      canActivateChild: [AuthGuard],  

  },
  {
    path: 'management',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/management/management.module').then(
        (m) => m.ManagementModule
      ),
      canActivateChild: [AuthGuard],  

  },
  {
    path: 'qrcode',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/qrcode/qrcode.module').then((m) => m.QrcodeModule),
      canActivateChild: [AuthGuard],  

  },
  {
    path: 'user-management',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/user-management/user-management/user-management.module').then((m) => m.UserManagementModule),
      canActivateChild: [AuthGuard],  
  },

  {
    path: 'leads',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/leads/leads.module').then((m) => m.LeadsModule),
      canActivateChild: [AuthGuard],  
  },

  {
    path: 'foodics-credential',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/foodics-credential/foodics-credential.module').then(
        (m) => m.FoodicsCredentialModule
      ),
      canActivateChild: [AuthGuard],  

  },
  {
    path: "reg-with-tap",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/reg-with-tap/reg-with-tap.module").then((m) => m.RegWithTapModule)

    
  },
  {
    path: "website",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/website-builder/website-builder.module").then((m) => m.WebsiteBuilderModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})


export class AppRoutingModule {}
