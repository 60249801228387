import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpcommanService } from "./httpshared.service";
@Injectable({
  providedIn: "root"
})
export class LoginService {
  constructor(
    private httpService: HttpcommanService
  ) { }

  login(data) {
    return this.httpService.postCall("/admin/auth/login", data);
  }
  getRoleDetail(id:any){
    return this.httpService.getCall(`/admin/admin-role/get-role-permission/${id}`);
  }





}
