import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpcommanService } from "./httpshared.service";
import { countryDialCodes } from "../Common/constants/countryDialCodes.constant";
import {  Router } from "@angular/router";
@Injectable({
  providedIn: "root"
})
export class CommanService {
  constructor(
    private http: HttpClient,
    private httpService: HttpcommanService,
    private route: Router
  ) { }
  changePassword(params: any) {
    return this.httpService.patchCall('/admin/auth/change-password', params);
  }

  pushBroadCast(data: any) {
    return this.httpService.postCall('pushBroadcast', data);
  }


  //Access from feedback component
  getAllFeedbacks() {
    return this.httpService.getCall('admin/feedbacks');
  }
  //Accessing from feedback component
  deleteFeedback(feedbackId: any) {
    return this.httpService.deleteCall('admin/deleteFeedback', feedbackId);
  }


  countryDialCodes(){
    return countryDialCodes;
  }

  getCountryList() {
    return this.httpService.getCall("/country/get-country");
  }
  
  setLocalStorage(key:any,value:any){
   value= JSON.stringify(value);
    localStorage.setItem(key,value)  
  }

  getLocalStorage(key:any){
   return (localStorage.getItem(key));
  }

  hasEditPermission(){
 
   let role= JSON.parse(this.getLocalStorage('role'));
   if(role=='ADMIN'){
    return true;
   }

   const routeUrl:any = this.route.url;

   // Extract the part of the route before the first forward slash after the base URL
   const extractedRoute:any = routeUrl.split('/').slice(1).join('/');
   let editEditPoint=this.getLocalStorage('editEndPoint');
   console.log(extractedRoute)
   let hasEditPermission = editEditPoint.includes(extractedRoute);
   if(hasEditPermission){
    return true;
   }
   return false;


  }
}
