import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { CommanService } from '../services/shared.service';

import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { REGEX_FOR_EMAIL } from 'src/app/Common/constants/app.constants';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  invalid: any = true;
  submitted = false;
  email: any;
  password: any;
  loginForm: FormGroup;
  constructor(private _login: LoginService, 
    private _route: Router, private formBuilder: FormBuilder, private snack: MatSnackBar,private CommanService:CommanService) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.loginForm = this.formBuilder.group({
      email: ['',[Validators?.required, Validators?.pattern(REGEX_FOR_EMAIL)]],
      password: ['', [Validators.required]],
    });
  }


  get f() {
    return this.loginForm.controls;
  }
  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      console.log(this.loginForm)
      return;
    }
    let login = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password
    }
    this._login.login(login).subscribe(
      result => {
        if (result.status == 200) {
          if(result.message =="Login Successfully."){
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('token', `Bearer ${result.data.token}`);
            if(!result?.data?.admin?.adminRole){
              this.CommanService.setLocalStorage('role','ADMIN');              
              this._route.navigate(['/restaurant']);  
              return ;
            }
            this.roleDetail(result?.data?.admin?.adminRole);

          }
          else if(result.message =="Account not found."){
            this.snack.open('Account not found', 'ok', { duration: 5000 })
          }
         
        }
        else this.invalid = false;
      },
      err => {
        if (err) {                   
          this.snack.open('Invalid email or password', 'ok', { duration: 5000 })
        }
        this.invalid = false;
      }
    );
  }


  roleDetail(accessId:any){
          this._login.getRoleDetail(accessId).subscribe((res:any)=>{
        if(res){
          this.transFormedArray(res?.data?.rolesList[0]?.permission);
        }
    },err=>{
      console.log(err);
    })
  }

  transFormedArray(roleList){
     let viewEndPoint=[];
     let editEndPoint=[]
     viewEndPoint=roleList.filter(res=> res.permissionName=='view').map(res=>res.endPoint);
      editEndPoint=roleList.filter(res=> res.permissionName=='edit').map(res=>res.endPoint);
      this.CommanService.setLocalStorage('viewEndPoint',viewEndPoint);
      this.CommanService.setLocalStorage('editEndPoint',editEndPoint);
      if(viewEndPoint)  
      console.log(viewEndPoint)
      this._route.navigate([viewEndPoint[0]]);

  }
}
