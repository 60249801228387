export const JWT_ACCESS_TOKEN = 'jwt_access_token';

export const UNAUTHENTICATED_RESPONSE_MESSAGE_CODE = 'GraphQL error: UNAUTHENTICATED';
export const UNAUTHENTICATED_RESPONSE_MESSAGE = 'Incorrect email address and / or password.';

export const CANNOT_DELETE_SELF_RESPONSE_MESSAGE_CODE = 'cannot-delete-self-account';
export const CANNOT_DELETE_SELF_RESPONSE_MESSAGE = 'Sorry, you cannot delete your own account.';

export const USER_ALREADY_EXISTS_RESPONSE_MESSAGE_CODE = 'create-user-exists';
export const USER_ALREADY_EXISTS_RESPONSE_MESSAGE = 'Sorry cannot create user, user with this email and/or mobile already exists.';

export const GENERAL_SERVER_ERROR_RESPONSE_MESSAGE = 'Sorry, we were unable to process the operation, please try again.';


export const CANNOT_DELETE_CHIT_RESPONSE_MESSAGE = 'Cannot delete chit, already in use.';
export const DELETE_CONFIRMATION_RESPONSE_MESSAGE = 'Are you sure you want to delete?';


export const REGEX_POSITIVE_NUMBERS = /^(0*[0-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/;
export const REGEX_POSITIVE_INTEGER_NUMBERS = /^[1-9]\d*$/;

export const REGEX_FOR_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

export const REGEX_FOR_WHITE_SPACE = /^[a-zA-Z0-9_]*$/;

export const REGEX_FOR_PASSWORD = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[\\\[\\\](){}?\\\\|,':"_@#$%.~^&+*)(!=\/-]).*$/;

export const REGEX_FOR_STRING_AND_NUMBERS = /^[a-zA-Z0-9]*$/; // [0-9][a-zA-Z]    // /^[a-zA-Z0-9_.-]*$/   ///^[\w]*$/   // /^([a-zA-Z0-9]+[_-])*[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/

export const REGEX_FOR_STRING = /^[a-zA-Z]*$/;
export const REGEX_FOR_9DIGIT = /^\d{9}$/;
export const REGEX_FOR_10DIGIT = /^\d{10}$/;

export const REGEX_FOR_STRING_WITH_WHITE_SPACE = /^[a-zA-Z ]*$/;
export const REGEX_FOR_WEBSITE_LINK =  /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\.[a-zA-Z]{2,})(\/[^\s]*)?$/;