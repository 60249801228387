import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private _router: Router, private snack: MatSnackBar) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token=localStorage.getItem('token');
    if(token){
      let httpRequest=request.clone({ setHeaders: { Authorization:token } });  
      return next.handle(httpRequest).pipe(
        catchError((error: any) => {
          let errorMsg = '';
          console.log("AuthenticationInterceptor_error", error.status, error?.message);
          if (error.status == 401) {
            localStorage.clear();
            this._router.navigate(['/login']);
          }
          if(error?.status == 500 && error?.error?.message == "jwt expired"){
            localStorage.clear();
            this._router.navigate(['/login']);
          }
          else if (error.status) {
            console.log('error_message', error.error.message)
            this.snack.open(error.error.message, 'ok',{duration:2000});
            // localStorage.clear();
            // this._router.navigate(['/login']);
            // this.snack.open(error.error.message, 'ok', {
            //   duration: 3000
            // })
          }
          return throwError(errorMsg);
        })
      )
    }
    else
      return next.handle(request);
  }
}
