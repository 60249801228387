import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appName'
})
export class AppNamePipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  appName: string = 'My Restro';

  transform(): string {
    return this.appName;
  }

}
