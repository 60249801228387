import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CommanService } from '../services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
   constructor(
    private $router: Router,
    private commanService:CommanService,
    private snack: MatSnackBar
  ) { }
  canActivate(   
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const accessToken = localStorage.getItem('token');

      const routeUrl = state.url;

      // Extract the part of the route before the first forward slash after the base URL
      const extractedRoute:any = routeUrl.split('/').slice(1).join('/');
      const viewEndPoint:any=  this.commanService.getLocalStorage('viewEndPoint');
      const role=JSON.parse(this.commanService.getLocalStorage('role'));

      if(accessToken){
        if(role && role=="ADMIN"){
          return true;
        }
          if(viewEndPoint && viewEndPoint.length>0 && viewEndPoint.includes(extractedRoute)){
              return true
           }else{
            // localStorage.clear();

            console.log('343',JSON.parse(viewEndPoint))
            let viewPoint=JSON.parse(viewEndPoint)
            
            this.$router.navigate([viewPoint[0]]);
            this.snack.open("You don't have access to this module", 'error', { duration: 4000 });
            return false;
          }


          }else{
        console.log('inside_if')
        this.$router.navigate(['/login']);
        return false;
      }
}

  canActivateChild(    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const accessToken = localStorage.getItem('token');
      console.log('==============')
      const routeUrl = state.url;

      // Extract the part of the route before the first forward slash after the base URL
      const extractedRoute:any = routeUrl.split('/').slice(1).join('/');
      const viewEndPoint:any=  this.commanService.getLocalStorage('viewEndPoint');
      const role=JSON.parse(this.commanService.getLocalStorage('role'));

      if(accessToken){
        if(role && role=="ADMIN"){
          return true;
        }
          if(viewEndPoint && viewEndPoint.length>0 && viewEndPoint.includes(extractedRoute)){
              return true
           }else{
            let viewPoint=JSON.parse(viewEndPoint)
            
            this.$router.navigate([viewPoint[0]]);
            this.snack.open("You don't have access to this module", 'error', { duration: 4000 });
            return false;
          }


          }else{
        console.log('inside_if')
        this.$router.navigate(['/login']);
        return false;
      }
}

}

