import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommanService } from '../../../../services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css'],
})
export class ChangepasswordComponent implements OnInit  {
  changePasswordForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private _router: Router,
    private _commonService: CommanService,
    private _matSnackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.changePasswordForm = this.formBuilder.group({
      confirmPassword: ['', [Validators.required,Validators.minLength(6)]],
      newPassword: ['', [Validators.required,Validators.minLength(6)]],
      oldPassword: ['', [Validators.required,Validators.minLength(6)]],
    });
  }
  get f() {
    return this.changePasswordForm.controls;
  }
  saveDetails() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    if (this.changePasswordForm.value.newPassword != this.changePasswordForm.value.confirmPassword) {
      this._matSnackBar.open('New Password and Confirm Password mismatched.', 'Close', {
        duration: 5000,
      });
      return;
    }
    this.changePasswordForm.value.email="admin@boba.com";
    let params={
      old_password:this.changePasswordForm.value.oldPassword,
      new_password:this.changePasswordForm.value.newPassword
    }
    
    this._commonService.changePassword(params).subscribe(
      result => { 
        console.log("saveDetails_2", result);
        this._matSnackBar.open(result.message, 'Close', {
          duration: 3000,
        });
        this._router.navigate(['/login']);
      },
      err => {
        this._matSnackBar.open(err.error.message, 'Close', {
          duration: 3000,
        });
      }
    );
  }
}
